<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else style="margin-bottom: 20%">
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Notification Detail</h1>
        </div>
        <div class="title-tabs mt-3">Notification Info</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col md="6" class="mb-3">
                <div class="d-flex align-items-center">
                  <span class="label">
                    Notifications Type <span class="text-danger">*</span></span
                  >
                </div>
                <InputSelect
                  title=""
                  name="Notifications Type"
                  isRequired
                  v-model="form.notification.id_type"
                  v-bind:options="notificationTypeList"
                  @onDataChange="handleChangenotificationType"
                  :disabled="isDisableEdit"
                  :noPleaseSelect="noPleaseSelect"
                  valueField="id"
                  textField="name"
                  :v="checkrequiredtype"
                />
              </b-col>
              <b-col md="6" class="mb-3">
                <div class="d-flex align-items-center">
                  <span class="label">
                    Notifications Code <span class="text-danger">*</span></span
                  >
                </div>
                <InputSelect
                  title=""
                  name="Notifications Code"
                  isRequired
                  v-model="form.notification.id_reference"
                  v-bind:options="notificationCode"
                  @onDataChange="handleChangenotificationCode"
                  :disabled="isDisable"
                  :noPleaseSelect="noPleaseSelect"
                  valueField="id"
                  textField="name"
                  :v="checkrequiredcode"
                />
              </b-col>
              <b-col md="6">
                <InputText
                  textFloat="Notification Name"
                  placeholder="Notification Name"
                  type="text"
                  name="notificationname"
                  isRequired
                  :disabled="isDisable"
                  v-model="form.notification.title"
                  :v="$v.form.notification.title"
                />
              </b-col>
              <b-col md="6" class="mb-3">
                <label class="font-weight-bold main-label">
                  Shot Description
                  <span class="text-danger">*</span>
                </label>
                <b-form-textarea
                  id="textarea-rows"
                  :value="form.notification.content"
                  :v-model="form.notification.content"
                  :disabled="isDisable"
                  rows="5"
                  @change="handleChangeDescription"
                >
                </b-form-textarea>
                <div v-if="$v.form.notification.title.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.notification.title.required == false"
                    >Please input text</span
                  >
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <label class="label-text">
                  Publish Date
                  <span class="text-danger">*</span>
                </label>
                <div class="input-container">
                  <datetime
                    :disabled="isDisable"
                    type="datetime"
                    :input-style="styleDatetime"
                    v-model="form.notification.schedule_time"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    ref="transferPublishDate"
                    schedule_time
                  ></datetime>
                  <div v-if="isDisable" :class="'icon-primary text-right'">
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="'pointer color-primary'"
                      color="#B41BB4"
                    />
                  </div>
                  <div
                    v-else
                    :class="'icon-primary text-right'"
                    @click="$refs.transferPublishDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="'pointer color-primary'"
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.notification.schedule_time.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.notification.schedule_time.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="footer-action mt-4">
          <b-col>
            <router-link to="/notification">
              <b-button class="btn-cancel btn-width">Cancel</b-button>
            </router-link>
          </b-col>
          <b-col
            class="text-right"
            v-if="id !== '0' && form.notification.status_name === 'Wait Send'"
          >
            <b-button
              class="main-color btn-w-200"
              @click="CancelNotification(id)"
            >
              Cancel Sending Message
            </b-button>
          </b-col>
          <b-col
            class="text-right"
            v-if="id !== '0' && form.notification.status_name === 'Send'"
          >
            <b-button
              class="main-color btn-w-200"
              @click="CancelNotification(id)"
            >
              Delete Sending Message
            </b-button>
          </b-col>
          <b-col class="text-right" v-if="id === '0'">
            <b-button class="main-color btn-save" @click="checkForm(0)">
              Save
            </b-button>
          </b-col>
        </div>
      </b-form>
    </div>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  name: "NotificationDetails",
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    ModalAlertError,
  },
  data() {
    return {
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      id: this.$route.params.id,
      isLoading: false,
      isDisable: false,
      modalMessage: "",
      isDisableEdit: false,
      isLoadingImage: false,
      noPleaseSelect: true,
      coverImgType: 1,
      showVideo: "",
      showPreview: "",
      notificationTypeList: [],
      notificationCode: [],
      isSuccess: false,
      imgModal: null,
      styleDatetime: {
        width: "100%",
        border: "none",
        background: "#fff !important",
      },
      msgModal: null,
      notificationTypeId: 1,
      linkButton: true,
      form: {
        notification: {
          id_type: 0,
          id_reference: 0,
          title: "",
          content: "",
          schedule_time: "",
        },
      },
      checkrequiredtype: {
        required: null,
      },
      checkrequiredcode: {
        required: null,
      },
    };
  },
  validations: {
    form: {
      notification: {
        title: { required },
        content: { required },
        schedule_time: { required },
      },
    },
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    getDatas: async function () {
      this.isDisable = true;
      if (this.id !== "0") {
        this.isDisableEdit = true;
        this.isLoading = true;
        await this.$store.dispatch("ActionGetNotificationByID", this.id);
        var data = await this.$store.state.moduleConnectAPI
          .stateGetNotificationByID;
        if (data.result == 1) {
          if (data.detail === null) {
            this.$refs.modalAlertError.show();
            this.modalMessage = "Wrong Information!";
          }
          data.detail = data.detail ? data.detail : {};
          this.form.notification = data.detail;

          var productCode = [
            {
              id: 0,
              name: data.detail.product_Code,
            },
          ];
          this.form.notification.id_reference = 0;
          this.notificationCode = productCode;
        }
      }
      this.GetNewsType();
    },
    async GetNewsType() {
      await this.$store.dispatch("ActionGetNewsType");
      var data = await this.$store.state.moduleConnectAPI.stateGetNewsType;
      if (data.result == 1) {
        this.isLoading = false;
        this.notificationTypeList = data.detail;
      }
    },
    async GetNotificationNews(id) {
      await this.$store.dispatch("ActionGetNotificationNews", id);
      var data = await this.$store.state.moduleConnectAPI
        .stateGetNotificationNews;
      if (data.result == 1) {
        this.notificationCode = data.detail;
        this.isLoading = false;
      }
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
    handleChangenotificationType(value) {
      this.form.notification.id_type = value;
      this.GetNotificationNews(value);
      if (value === 0) {
        this.form.notification.id_reference = 0;
        this.isDisable = true;
      } else if (value === 3 || value === 1) {
        this.form.notification.id_reference = 0;
      } else {
        this.isDisable = false;
      }
    },
    handleChangenotificationCode(value) {
      this.form.notification.id_reference = value;
    },
    handleChangeDescription(value) {
      this.form.notification.content = value;
    },
    checkForm: async function (e) {
      if (this.form.notification.id_type === 0) {
        this.checkrequiredtype.required = false;
      } else {
        this.checkrequiredtype.required = true;
      }
      if (this.form.notification.id_reference === 0) {
        this.checkrequiredcode.required = false;
      } else {
        this.checkrequiredcode.required = true;
      }
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      this.isLoading = true;
      this.form.notification.id_reference = parseInt(
        this.form.notification.id_reference
      );
      await this.$store.dispatch(
        "ActionPushNotification",
        this.form.notification
      );
      var data = await this.$store.state.moduleConnectAPI.statePushNotification;
      if (data.result == 1) {
        this.isDisable = false;
        this.isLoading = false;
        this.successAlert().then(() => {
          this.$router.push(`/notification`);
        });
      } else {
        this.isDisable = false;
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },
    async CancelNotification(id) {
      this.isLoading = true;
      var setData = {
        id_notification: id,
      };
      await this.$store.dispatch("ActionCancelNotification", setData);
      var data = await this.$store.state.moduleConnectAPI
        .stateCancelNotification;
      if (data.result == 1) {
        this.isLoading = false;
        this.successAlert().then(() => {
          this.$router.push(`/notification`);
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },
  },
};
</script>

<style scoped>
.btn-w-200 {
  width: 200px;
  color: #fff;
  background-color: var(--primary-color);
  border: 1px solid #fff;
  border-radius: 0.25rem;
}


.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
